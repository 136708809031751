import ImageCarousel from '@/components/fileUploading/imageCarousel';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL } from '@/data/apollo';
import { LineItemRead, LineItemWrite } from '@/data/management/lineItem.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import ModifierGroupsSelect from '@/pages/dashboard/commerce/form/lineItemForm/modifierGroupsSelect';
import { useGetStoreAtom, useStorePublicRead, useUpdateOnlineStoreAtom } from '@/pages/p/store/context';
import { StoreImageAspectRatio, StoreModalImageHeight, StoreModalImageWidth } from '@/pages/p/store/utils/images';
import { useModalControls } from '@/providers/modal';
import { Item, LineItem, ModifierGroup, MutationLineItemWriteArgs } from '@/types/schema';
import { Close as CloseIcon, Share as ShareIcon } from '@mui/icons-material';
import {
	Box,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { flatMap } from 'fp-ts/Array';
import { isEmpty, isEqual, pick, sortBy } from 'lodash-es';
import { customAlphabet } from 'nanoid';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import LineItemFooter from './lineItemFooter';

const nanoid = customAlphabet( '23456789ABCDEFGHJKLMNPQRSTUVWXYZ', 8 );

function filterOutModifierIds( metadata: any, modifierGroups?: ModifierGroup[] ) {
	if ( !modifierGroups ) return [];
	return flatMap( modifierGroups, ( group ) => group.modifiers?.map( ( modifier ) => modifier?.externalId )
		.filter( ( id ) => metadata[ id ] > 0 ) as string[],
	);
}

export default function StorePublicLineItemsModal( {
	storeItem,
	orderItem,
}: {
	storeItem?: Partial<LineItem>,
	orderItem?: Partial<LineItem> | Partial<Item & { quantity: number | undefined }>
} ) {
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const setOnlineStoreAtom = useUpdateOnlineStoreAtom();
	const { closeModal } = useModalControls();
	const queryClient = useQueryClient();
	const { store } = useStorePublicRead();
	
	const { atomOrderId } = useGetStoreAtom();
	
	return (
		<FormGraphqlProvider<LineItem & { serviceDate: Date }>
			validateOnMount
			id={storeItem?.id || orderItem?.id}
			queryKey='lineItem'
			query={LineItemRead}
			initialValues={() => ( { unit: 'Unit', quantity: 1, serviceDate: new Date() } )}
			onSubmit={async ( { ...values } ) => {
				// If there is an order ID use it, otherwise create an order
				const mutateOrderId = atomOrderId
					? atomOrderId
					: ( await axios.post( '/api/orderPublicWrite', {
						store,
						id             : atomOrderId,
						orderNumber    : nanoid(),
						companyLocation: store.location?.id,
					} ) ).data.orderWrite.id;
				
				// Check if the store item and order item are the same
				const shouldUpdateExistingOrderItem = storeItem && orderItem && isEqual(
					{
						modifierIds: sortBy( filterOutModifierIds( values.metadata, values.modifierGroups ) ),
						note       : values.note,
					},
					{
						modifierIds: sortBy( filterOutModifierIds( orderItem?.metadata, orderItem?.modifierGroups ) ),
						note       : orderItem?.note ?? null,
					},
				);
				
				// Create Or Update Order LineItem
				await mutateGraphQL<MutationLineItemWriteArgs>( {
					mutation : LineItemWrite,
					variables: {
						id   : shouldUpdateExistingOrderItem || !storeItem ? orderItem?.id : undefined,
						input: {
							...pick( values, [
								'name',
								'price',
								'sequence',
								'description',
								'unit',
								'tax',
								'externalId',
								'note',
								'orderTax',
							] ),
							image : values.images?.[ 0 ] || values.item?.image,
							images: !isEmpty( values.images )
								? values.images
								: [ values.item?.image ].filter( Boolean ),
							// for the case where adding the item for the first time to the cart, we save the store item id as the parent line item
							metadata      : { ...values.metadata, ...!orderItem?.id && storeItem?.id && { parentLineItem: storeItem.id } },
							quantity      : shouldUpdateExistingOrderItem
								? orderItem.quantity + values.quantity
								: values.quantity,
							order         : mutateOrderId,
							modifierGroups: values.modifierGroups?.map( ( { id } ) => id ),
							uom           : values.uom?.id || null,
							item          : values.item?.id || null,
						},
					},
				} );
				
				if ( atomOrderId ) {
					await queryClient.invalidateQueries( [ 'orderPublicRead' ] );
				} else {
					setOnlineStoreAtom( { atomOrderId: mutateOrderId } );
				}
			}}>
			{( formik ) => (
				<Fragment>
					<DialogTitle>
						<ListItem disablePadding>
							<ListItemText
								primary={formik.values.name}
								primaryTypographyProps={{ variant: 'h5' }}
							/>
							<Stack direction='row' alignItems='center' spacing={1}>
								{storeItem?.id && (
									<Tooltip title='Share Item'>
										<IconButton
											onClick={async () => {
												await navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SERVER_URL}${router.asPath}?i=${storeItem.id}` );
												enqueueSnackbar( 'Link copied successfully!', { variant: 'success' } );
											}}>
											<ShareIcon/>
										</IconButton>
									</Tooltip>
								)}
								<IconButton size='small' onClick={() => closeModal()}>
									<CloseIcon/>
								</IconButton>
							</Stack>
						
						</ListItem>
					</DialogTitle>
					<DialogContent dividers>
						<Stack spacing={2}>
							{( Boolean( formik.values?.images ) || Boolean( formik.values.image ) ) && (
								<Grid item xs={12}>
									<ImageCarousel
										images={formik.values?.images || [ formik.values?.image ] || []}
										aspectRatio={StoreImageAspectRatio}
										displayImageWidth={StoreModalImageWidth}
										displayImageHeight={StoreModalImageHeight}
										smallImageWidth={60}
										smallImageHeight={60}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<Stack spacing={2}>
									<Typography variant='h4'>
										{formik.values.name}
									</Typography>
									{formik.values.description && (
										<Typography
											color='text.secondary'
											sx={{
												maxHeight : 120,
												overflow  : 'overlay',
												whiteSpace: 'pre-line',
											}}>
											{formik.values.description}
										</Typography>
									)}
								</Stack>
							</Grid>
							<ModifierGroupsSelect removeModifierQuantityPicker/>
							<Box>
								<FormTextField
									fullWidth
									multiline
									name='note'
									label='Note'
									placeholder='Leave a note for this item'
									variant='outlined'
									inputProps={{ maxLength: 3000 }}
									rows={3}
								/>
							</Box>
						</Stack>
					</DialogContent>
					<DialogActions>
						<LineItemFooter storeItem={storeItem} orderItem={orderItem}/>
					</DialogActions>
				</Fragment>
			)}
		</FormGraphqlProvider>
	);
}

import type { Address } from '@/types/schema';
import { Loader } from '@googlemaps/js-api-loader';
import { Box, Paper } from '@mui/material';
import { Circle, GoogleMap, GoogleMapProps, Libraries, Marker, OverlayView } from '@react-google-maps/api';
import { Fragment, ReactNode, useState } from 'react';
import { useAsyncEffect } from 'rooks';

export default function GoogleMapComp( {
	radius,
	address,
	googleMapProps,
	circleProps,
	markerProps,
	children,
	customMarker,
}: {
	radius?: number,
	address?: Partial<Address>,
	googleMapProps?: GoogleMapProps,
	circleProps?: Circle['props'],
	markerProps?: Marker['props'],
	children?: ReactNode,
	customMarker?: ReactNode
} ) {
	const centerPlaceHolder = { lat: 37.7749, lng: -122.4194 };
	const [ center, setCenter ] = useState( centerPlaceHolder );
	
	useAsyncEffect( async () => {
		if ( address && ( !address.lat || !address.lng ) && address.line1 ) {
			const loaderOptions = {
				apiKey   : 'YOUR_GOOGLE_MAPS_API_KEY',
				libraries: [ 'places' ] as Libraries,
			};
			const loader = new Loader( loaderOptions );
			
			await loader.importLibrary( 'geocoding' );
			
			const geocoder = new google.maps.Geocoder();
			await geocoder.geocode( { address: address.line1 }, ( results, status ) => {
				if ( status === 'OK' && results[ 0 ] ) {
					const location = results[ 0 ].geometry.location;
					setCenter( { lat: location.lat(), lng: location.lng() } );
				} else {
					setCenter( centerPlaceHolder );
				}
			} );
		} else if ( address?.lat && address?.lng ) {
			setCenter( { lat: address.lat, lng: address.lng } );
		} else {
			setCenter( centerPlaceHolder );
		}
	}, [ address ] );
	
	return (
		<Paper sx={{ height: 200, width: '100%' }}>
			<GoogleMap
				zoom={12}
				center={center}
				id='circle-example'
				clickableIcons={false}
				{...googleMapProps}
				options={{
					fullscreenControl: false,
					streetViewControl: false,
					zoomControl      : false,
					mapTypeControl   : false,
					...googleMapProps?.options,
				}}
				mapContainerStyle={{
					width       : '100%',
					height      : 200,
					margin      : 'auto',
					borderRadius: '7px',
					...googleMapProps?.mapContainerStyle,
				}}>
				{!children && (
					<Fragment>
						{customMarker ? (
							<OverlayView
								position={center}
								mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
								<Box
									className='center'
									sx={{
										bgcolor     : '#000000',
										width       : 40,
										height      : 40,
										borderRadius: '50%',
										border      : '2px solid #ffffff',
										transform   : 'translate(-50%, -100%)',
									}}>
									{customMarker}
								</Box>
							</OverlayView>
						) : (
							// Default Google Marker if customMarker is not provided
							<Marker position={center} {...markerProps}/>
						)}
						<Circle
							radius={radius ? radius * 1609.34 /* Convert miles to meters */ : 80}
							center={center}
							{...circleProps}
							options={{
								strokeOpacity: 0.8,
								strokeWeight : 2,
								strokeColor  : 'rgb(143,143,143)',
								fillColor    : 'rgba(107,107,107,0.67)',
								fillOpacity  : 0.35,
								clickable    : false,
								draggable    : false,
								editable     : false,
								visible      : true,
								...circleProps?.options,
							}}
						/>
					</Fragment>
				)}
				{children}
			</GoogleMap>
		</Paper>
	);
}

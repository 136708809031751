import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import InputStepper from '@/components/form/fields/InputStepper';
import currencyFormat from '@/helpers/currencyFormat';
import { useStorePublicRead } from '@/pages/p/store/context';
import { LineItem } from '@/types/schema';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty, isNumber, partition } from 'lodash-es';
import { useMemo } from 'react';

export default function LineItemFooter( { orderItem, storeItem }: { orderItem?: LineItem, storeItem?: LineItem } ) {
	const formik = useFormikContext<LineItem & { modifier: string }>();
	const { store } = useStorePublicRead();
	
	const modifiers = formik.values.metadata ?? {};
	
	const total = useMemo( () => {
		const modifiersTotal = ( formik.values.modifierGroups ?? [] )
			.map( ( mGroup ) => mGroup.modifiers )
			.flat()
			.filter( ( modifier ) => modifiers[ modifier.externalId ] )
			.reduce( ( sum, m ) => sum + ( m?.value ?? 0 ) * ( modifiers[ m.externalId ] ?? 0 ), 0 );
		
		let total = ( formik.values.price + modifiersTotal ) * formik.values.quantity;
		const [ discounts, fees ] = partition( formik.values.prices, ( { value } ) => value < 0 );
		total += fees.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * ( price.quantity || 1 ) ), 0 );
		total += discounts.reduce( ( sum, price ) => sum + ( price.isPercent
			? price.value * total / 100
			: price.value * ( price.quantity || 1 ) ), 0 );
		
		return total;
		
	}, [ formik.values ] );
	
	const modifiersValid = useMemo( () => {
		if ( isEmpty( formik.values.modifierGroups ) ) {
			return true;
		}
		for ( const mg of formik.values.modifierGroups ) {
			const pickedModifiers = mg.modifiers?.filter( ( modifier ) => formik.values.metadata[ modifier.externalId ] > 0 );
			let totalItems = 0;
			for ( const modifier of pickedModifiers ) {
				const modifierCount = formik.values.metadata[ modifier.externalId ];
				totalItems += modifierCount;
			}
			
			if ( isNumber( mg.min ) && totalItems < mg.min ) {
				return false;
			}
		}
		return true;
	}, [ formik.values ] );
	
	// Clicked from the cart
	const orderParentLineItem = !storeItem
		? store.lineItems.find( ( lineItem ) => lineItem.id === orderItem?.metadata?.parentLineItem )
		: null;
	
	const orderItemStockIsSameAsStoreItemStock = orderItem?.quantity === ( storeItem || orderParentLineItem )?.uom?.quantity;
	
	const disabled = store.requireInStock && ( ( formik.values.uom?.quantity || 0 ) < formik.values.quantity || !modifiersValid || orderItemStockIsSameAsStoreItemStock );
	
	const disabledMessage = store.requireInStock && ( ( formik.values.uom?.quantity || 0 ) < formik.values.quantity || orderItemStockIsSameAsStoreItemStock )
		? 'Not enough available in stock'
		: !modifiersValid
			? 'Please select all required modifiers'
			: '';
	
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={{
				'width'                 : '100%',
				'p'                     : 2,
				'.MuiOutlinedInput-root': { height: 30, fontSize: '2rem', width: 70 },
			}}>
			<InputStepper minVal={1} name='quantity'/>
			<Tooltip title={disabledMessage}>
				<Box>
					<AsyncLoadingButton
						variant='contained'
						size='medium'
						color='primary'
						sx={{ alignSelf: 'right' }}
						disabled={disabled}
						onClick={formik.submitForm}>
						{storeItem ? 'Add to Cart' : 'Update'}
						<Typography component='span' pl={{ xs: 1, sm: 10 }}>
							{currencyFormat( total || 0 )}
						</Typography>
					</AsyncLoadingButton>
				</Box>
			</Tooltip>
		</Stack>
	);
}
